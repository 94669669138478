function toggleSearchWindow() {
  const searchWindow = document.getElementById('search-popup');
  const searchBtn = document.querySelector('.search-toggle');

  if (searchWindow.classList.contains('open')) {
    searchWindow.classList.remove('open');
    searchBtn.setAttribute('aria-expanded', 'false');
    searchBtn.classList.remove('open-search-windows');
  } else {
    searchWindow.classList.add('open');
    searchBtn.setAttribute('aria-expanded', 'true');
    searchBtn.classList.add('open-search-windows');
  }
}

window.addEventListener('load', function () {
  const searchBtn = document.querySelector('.search-toggle');
  const searchBtnClose = document.querySelector('.search-close');

  if (searchBtn) {
    searchBtn.addEventListener('click', toggleSearchWindow);
  }
  if (searchBtnClose) {
    searchBtnClose.addEventListener('click', toggleSearchWindow);
  }
});
