(function ($) {
  let UIController = (function () {
    let DOMstrings = {
      accordionRow: '.accordion__row',
      accordionToggle: '.accordion__toggle',
      accordionBody: '.accordion__body',
      accordionTitle: '.accordion__title',
    };

    return {
      getDOMstrings: function () {
        return DOMstrings;
      },
      toggleaccordionsAccordeon: function (el) {
        const $accordion_wrapper = $(el).parent();
        const $accordion_content = $(el)
          .parent()
          .find('.accordion__body')
          .first();
        const $accordion_open = 'expanded';

        // If this accordion is already open
        if ($accordion_wrapper.hasClass($accordion_open)) {
          $accordion_content.slideUp();
          // Close the content.
          $accordion_wrapper.removeClass($accordion_open); // Remove the accordionm--open class.
        }
        // If this accordion is not already open
        else {
          $accordion_content.slideDown();
          // Show this accordion's content.
          $accordion_wrapper.addClass($accordion_open); // Add the accordion--open class.
        }
      },
    };
  })();

  let controller = (function (UICtrl) {
    let setupEventListeners = function () {
      let DOM = UICtrl.getDOMstrings();

      $(DOM.accordionTitle).on('click', function () {
        UICtrl.toggleaccordionsAccordeon($(this));
      });

      $('.close-accordion').on('click tap', function () {
        $(this)
          .parents('.accordion__body')
          .fadeOut()
          .parents('.accordion__row')
          .removeClass('expanded');
      });

      // Get the container and link elements
      const container = document.querySelector('.discover .links');
      if (container){
        const linkElements = container.querySelectorAll('.link');

        // Calculate the available width of the container
        const containerWidth = container.offsetWidth;
        console.log(containerWidth);

        // Initialize variables to keep track of accumulated effective width and the index of the link on the next line
        let accumulatedWidth = 0;
        let nextLineIndex = -1;

        // Loop through the link elements to find the one on the next line
        for (let i = 0; i < linkElements.length; i++) {
          const link = linkElements[i];

          // Get the effective width of the link, including margins, padding, and border
          const linkEffectiveWidth =
            link.offsetWidth +
            parseFloat(getComputedStyle(link).marginRight) +
            parseFloat(getComputedStyle(link).borderLeftWidth);
          console.log(linkEffectiveWidth);
          // Check if adding the current link's effective width would cause overflow
          if (accumulatedWidth + linkEffectiveWidth > containerWidth) {
            nextLineIndex = i;
            break;
          }

          // Add the link's effective width to the accumulated width
          accumulatedWidth += linkEffectiveWidth;
          console.log(accumulatedWidth);
        }
        // Add a class to the link element on the next line
        if (nextLineIndex !== -1) {
          linkElements[nextLineIndex].classList.add('next-line-element');
        }
        console.log(
          'The first link element on the next line is at index:',
          nextLineIndex
        );
      }

    };

    return {
      init: function () {
        setupEventListeners();
      },
    };
  })(UIController);
  controller.init();
})(jQuery);
