let fields_are_initialized = false;

function change_file_upload() {
  const multiFileUploads = document.querySelectorAll(
    '.gform_fileupload_multifile'
  );
  if (0 < multiFileUploads.length) {
    multiFileUploads.forEach(function (fileUpload) {
      console.log(fileUpload);
      fileUpload.querySelector('.gform_drop_instructions').textContent =
        wp.i18n._x(
          'Drag and drop file to upload',
          'drag and drop instruction',
          'vtx'
        );
    });
  }
}

document.addEventListener('gform_post_render', function () {
  fields_are_initialized = true;
  change_file_upload();
});

// ON READY
window.addEventListener('load', function () {
  if (!fields_are_initialized) {
    change_file_upload();
  }
});
