window.addEventListener('load', function () {
  const Swiper = window.Swiper;
  if (document.querySelector('.front-page-header__carousel')) {
    new Swiper('.front-page-header__carousel', {
      direction: 'horizontal',
      loop: false,
      watchOverflow: false,
      paginationClickable: true,
      centeredSlides: true,
      autoplay: {
        delay: 5000,
        disableOnInteraction: true,
        pauseOnMouseEnter: true,
      },
      mousewheel: {
        enabled: true,
        forceToAxis: true,
      },
      keyboard: true,
      pagination: {
        el: '.front-page-header__carousel .swiper-pagination',
        clickable: true,
      },
      navigation: {
        nextEl: '.front-page-header__carousel .swiper-button-next',
        prevEl: '.front-page-header__carousel .swiper-button-prev',
      },
    });
  }
});
