/* *
 * ========================================================================
 * Vortex Solution
 * ========================================================================
 * */

// Includes the custom Modernizr build based on the configurations set in .modernizrrc
import Swiper from 'swiper/bundle';
window.Swiper = Swiper;
require('Modernizr'); // eslint-disable-line import/no-extraneous-dependencies
require('nodelist-foreach-polyfill');
require('element-qsa-scope');
require('./main/dropdown-menu');
require('./main/accordions');
require('./main/mobile-menu');
require('./main/user-is-tabbing');
require('./main/forms');
require('./main/footer-popup');
require('./main/front-page-header');
require('./main/search-window');
require('./main/template-scrollup');

import vtxDomReady from './common/helpers';
import intrinsicRatioVideos from './common/video-ratios';

vtxDomReady(() => {
  // ------------------ Offset anchor linked to another page
  // The function actually applying the offset
  const offsetAnchor = () => {
    if (location.hash.length !== 0) {
      window.scrollTo(window.scrollX, window.scrollY - 120);
    }
  };

  const anchorLinks = document.querySelectorAll('a[href*="#"]');

  anchorLinks.forEach((anchorLink) => {
    anchorLink.addEventListener('click', () => {
      // Click events are captured before hashchanges. Timeout
      // causes offsetAnchor to be called after the page jump.
      window.setTimeout(() => {
        offsetAnchor();
      }, 0);
    });
  });

  // Set the offset when entering page with hash present in the url
  window.setTimeout(offsetAnchor, 0);
  // ------------------ END Offset anchor linked to another page

  intrinsicRatioVideos.init(); // Retain aspect ratio of videos on window resize.
});
