import vtxDomReady from '../common/helpers';

vtxDomReady(() => {
  const openPopupBtn = document.querySelectorAll('.openPopupBtn');
  const footerPopup = document.querySelectorAll('.footerPopup');
  const footerPopupOverlay = document.querySelectorAll('.footerPopupOverlay');
  const footerPopupCloseBtn = document.querySelectorAll('.footerPopupCloseBtn');

  openPopupBtn.forEach((el) => {
    const btnFormNumber = el.dataset.formNumber;
    el.addEventListener('click', () => {
      footerPopup.forEach((popup) => {
        const formNumber = popup.dataset.formNumber;
        if (btnFormNumber === formNumber) {
          popup.classList.add('displayPopup');
        }
      });
    });
  });

  footerPopupOverlay.forEach((el) => {
    el.addEventListener('click', () => {
      footerPopup.forEach((popup) => {
        popup.classList.remove('displayPopup');
      });
    });
  });

  footerPopupCloseBtn.forEach((el) => {
    el.addEventListener('click', () => {
      footerPopup.forEach((popup) => {
        popup.classList.remove('displayPopup');
      });
    });
  });
});
